<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="公司名称">
                                <el-input v-model="search.company_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="活动名称">
                                <el-input v-model="search.activity_title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="发布状态">
                                <el-select v-model="search.publish_state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="已发布" :value="1"></el-option>
                                    <el-option label="未发布" :value="0"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核状态">
                                <el-select v-model="search.examine_status" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="审核中" :value="0"></el-option>
                                    <el-option label="审核通过" :value="1"></el-option>
                                    <el-option label="审核失败" :value="2"></el-option>
                                    <el-option label="违规下架" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动品类">
                                <el-input v-model="search.motion_category"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动效果">
                                <el-input v-model="search.target_category"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="时间">
                                <el-select v-model="search.time_type" style="width: 30%">
                                    <el-option label="报名开始时间" value="signup_start_time"></el-option>
                                    <el-option label="活动开始时间" value="start_time"></el-option>
                                </el-select>
                                <el-date-picker style="width: 70%" v-model="searchtime" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('product.activity.setvirtualenrollment') && chooseBatchActivity.length > 0"
                            size="medium" type="primary" @click="openBatch">
                            批量优化
                        </el-button>
                        <el-button icon="el-icon-download" size="medium" type="primary" @click="exportList">导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" ref="activityTable" :data="tableData" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" :selectable="selectable">
                </el-table-column>
                <el-table-column prop="company_name" label="公司名称" width="200">
                </el-table-column>
                <el-table-column prop="activity_title" label="活动名称" width="200">
                </el-table-column>
                <el-table-column prop="coupon_activity_qrcode" label="活动二维码" width="60">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.activity_qrcode)">
                            <el-avatar shape="square" :size="22" :src="scope.row.activity_qrcode"></el-avatar>
                        </viewer>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="signup_end_time" label="报名截止时间" width="160">
                </el-table-column>
                <el-table-column prop="originally_price" label="报名人数/人数上限" width="140">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bookings_sum }}</span>
                        <span>/{{ scope.row.quota }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="virtual_enrollment_num" label="优化人数" width="60">
                </el-table-column>
                <el-table-column prop="virtual_enrollment_num" label="优化后购买人数">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bookings_sum + scope.row.virtual_enrollment_num }}</span>
                        <span>/{{ scope.row.quota }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="originally_price" label="活动地址" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.activity_type === 1">
                            {{ scope.row.county }}{{ scope.row.road }}{{ scope.row.address }}
                        </span>
                        <span v-else>线上活动</span>
                    </template>
                </el-table-column>
                <el-table-column prop="detailed_time" label="活动时间" width="200">
                    <template slot-scope="scope">
                        <el-popover v-if="scope.row.time_type === 2" placement="right" width="200" trigger="click">
                            <el-table :data="scope.row.detailed_time">
                                <el-table-column width="205" property="date" label="活动时间">
                                    <template slot-scope="scope2">
                                        {{ scope2.row.activity_date }}
                                        ({{ scope2.row.activity_time[0] }}至{{ scope2.row.activity_time[1] }})
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button type="text" size="mini" slot="reference">
                                查看，共{{ scope.row.detailed_time.length }}天
                            </el-button>
                        </el-popover>
                        <span v-else-if="scope.row.time_type === 3">
                            {{ scope.row.start_time }}至{{ scope.row.end_time }}
                        </span>
                        <span v-else>
                            {{ scope.row.detailed_time[0].activity_date }}
                            ({{
                                scope.row.detailed_time[0].activity_time[0]
                            }}至{{ scope.row.detailed_time[0].activity_time[1] }})
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="motion_category_two" label="运动品类" width="200">
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.motion_category_two" class="form-tag_colour-tag"
                            effect="dark" size="mini">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="target_category_zero" label="运动效果" width="200">
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.target_category_zero" class="form-tag_colour-tag"
                            effect="dark" size="mini">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="originally_price" label="价格（原价/售价）" width="200">
                    <template slot-scope="scope">
                        <template v-if="scope.row.sku_type === 0">
                            <span>￥{{ scope.row.originally_price }}</span>
                            <span v-if="scope.row.promotion_price > 0">/￥{{ scope.row.promotion_price }}</span>
                        </template>
                        <template v-else>
                            <el-popover placement="right" width="200" trigger="hover">
                                <el-table :data="scope.row.sku_data">
                                    <el-table-column width="200" property="date" label="活动规格">
                                        <template slot-scope="scope2">
                                            {{ `${scope2.row.sku_name}：¥${scope2.row.sku_price}` }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-button type="text" size="mini" slot="reference">多规格活动
                                </el-button>
                            </el-popover>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state_x === 4" type="info">{{ scope.row.state_name }}</el-tag>
                        <el-popover v-else-if="scope.row.state_x === 5" placement="right" width="200" trigger="click">
                            <div>
                                <div>操作方：
                                    <span v-if="scope.row.withdraw_type === 'oss'">平台管理员</span>
                                    <span v-if="scope.row.withdraw_type === 'merchant'">商户端</span>
                                </div>
                                <div>下架时间：{{ scope.row.withdraw_time }}</div>
                                <div>下架原因：{{ scope.row.withdraw_remarks }}</div>
                            </div>
                            <el-tag size="mini" type="danger" slot="reference">{{
                                scope.row.state_name
                            }}
                            </el-tag>
                        </el-popover>
                        <el-tag size="mini" v-else-if="scope.row.state_x === 6" type="warning">{{
                            scope.row.state_name
                        }}
                        </el-tag>
                        <el-tag size="mini" v-else type="success">{{ scope.row.state_name }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="examine_status" label="审核状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="is_refund" label="允许退款" width="250">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_refund === 1">是：退款截止时间{{
                            scope.row.is_refund_end_time
                        }}
                        </el-tag>
                        <el-tag size="mini" v-if="scope.row.is_refund === 0" type="warning">否</el-tag>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="420">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('order.activity.getlist')" @click="order_page(scope.row)" size="mini">
                            查看订单
                        </el-button>
                        <el-button v-if="is_auth('product.activity.getinfo')" @click="tosee(scope.row.activity_uuid)"
                            size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('product.activity.examine') && scope.row.examine_status === 0"
                            @click="operation_tip(scope.row.activity_uuid, scope.row.activity_title, 'examine', 1)"
                            type="success" size="mini">通过
                        </el-button>
                        <el-button v-if="is_auth('product.activity.examine') && scope.row.examine_status === 0"
                            @click="operation_tip(scope.row.activity_uuid, scope.row.activity_title, 'examine', 2)"
                            type="danger" size="mini">不通过
                        </el-button>
                        <el-button
                            v-if="is_auth('product.activity.examine') && scope.row.examine_status !== 0 && scope.row.examine_status !== 3"
                            @click="operation_tip(scope.row.activity_uuid, scope.row.activity_title, 'illegal', 3)"
                            type="danger" size="mini">违规下架
                        </el-button>
                        <el-button v-if="is_auth('product.activity.setvirtualenrollment')"
                            @click="getSingleActivity(scope.row)" type="primary" size="mini"
                            :disabled="time_decide(scope.row.signup_end_time, '<=') || scope.row.state === 5">数量优化
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <!--订单内容-->
        <el-dialog v-loading="loading" v-if="is_auth('store.coach.getcoach')" title="活动订单" :visible.sync="order_dialog"
            width="70%" :close-on-press-escape="false" :close-on-click-modal="false">
            <el-row>
                <OrderList ref="order_list" />
            </el-row>
        </el-dialog>
        <el-dialog :visible.sync="isEditShow" title="数据优化" width="50%" :close-on-press-escape="false"
            :close-on-click-modal="false" @close="clearState">
            <el-row>
                <el-form size="medium" ref="form" :label-width="this.env.label_width">
                    <!-- 如果是单独优化，显示活动信息 -->
                    <div v-if="optimizeType === 'single' && chooseActivity">
                        <el-col :span="24">
                            <el-form-item label-width="140px" label="活动名称：">
                                <span>{{ chooseActivity.activity_title }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label-width="140px" label="当前报名用户数：">
                                <span>{{ chooseActivity.bookings_sum }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label-width="140px" label="当前已优化数：">
                                <span>{{ chooseActivity.virtual_enrollment_num }}</span>
                            </el-form-item>
                        </el-col>
                    </div>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="优化报名用户数：">
                            <el-input-number :min="1" v-model="optimizeNumber" :step="1" step-strictly placeholder="优化数量" />
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin: 10px 0px">
                <el-button style="margin-right:8px" v-if="is_auth('product.activity.setvirtualenrollment')" size="medium"
                    type="primary" @click="submit">
                    确认
                </el-button>
                <el-popconfirm v-if="optimizeType === 'single' && is_auth('product.activity.clearvirtualenrollment')"
                    confirm-button-text='确定' cancel-button-text='取消' title="确定清空当前活动优化数据吗？" @confirm="clearOptimizeNumber">
                    <el-button slot="reference" size="medium" type="primary">
                        清空优化数据
                    </el-button>
                </el-popconfirm>
                <el-button style="margin-left:8px" size="medium" @click="clearState">取消</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import OrderList from './page/OrderList'

export default {
    components: {
        OrderList
    },
    data() {
        return {
            page_name: '活动列表',
            loading: true,      // 加载状态
            issearch: true,    // 搜索是否展示
            order_dialog: false,    // 搜索是否展示
            search: {},         // 搜索内容
            searchtime: null,    // 时间条件
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容

            chooseBatchActivity: [], // 当前多选的活动
            chooseActivity: null, // 当前选中的活动
            isEditShow: false, // 数据优化弹窗是否显示
            optimizeNumber: 1, // 优化数
            optimizeType: "", // 优化类型 single --> 单独优化 batch --> 批量优化
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 展示订单
        order_page(activity_info) {
            this.order_dialog = true
            // console.log('activity_info', activity_info)
            let thi = this
            setTimeout(function () {
                thi.$refs.order_list.init(activity_info)
            }, 300);
        },
        // 判断是否为空
        time_decide(time, decide = '<',) {
            return this.Tool.time_decide(time, decide)
        },     // 判断是否为空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                activity_title: '',
                publish_state: '',
                tag_s: '',
                examine_status: "",
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "product.activity.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(activity_uuid = "", title = "", operation = "", state) {
            let tip = "";
            let options = {};
            // 启用
            if (operation === "examine") {
                tip = "启用【" + title + "】？";
                if (state === 1) {
                    tip = '审批通过【' + title + '】？';
                    // 弹出二次确认
                    this.$confirm(tip, "确认信息", options).then(() => this.isoperation(activity_uuid, operation, state))
                } else {
                    tip = '不通过【' + title + '】？';
                    // 弹出二次确认
                    this.$prompt("请输入不通过理由", tip).then(({ value }) => this.isoperation(activity_uuid, value, state))
                }
            }
            if (operation === "illegal") {
                tip = "违规下架【" + title + "】？";
                // 弹出二次确认
                this.$prompt("请输入违规下架原因", tip).then(({ value }) => this.isoperation(activity_uuid, value, state))
            }
        },
        // 操作
        isoperation(activity_uuid = "", value, state) {
            let postdata = {
                api_name: "product.activity.examine",
                token: this.Tool.get_l_cache("token"),
                activity_uuid,
                examine_status: state
            };
            if (value) {
                postdata["examine_reason"] = value;
            }
            this.loading = true;
            this.Tool.post_data("oss", postdata, (json) => {
                this.loading = false;
                if (json.code === 0) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist(); // 刷新列表(重新搜索)
                        },
                    });
                } else {
                    this.Tool.errormes(json);
                }
            });
        },
        //查看
        tosee(activity_uuid = '') {
            this.$router.push({ path: '/product/activity/details', query: { activity_uuid } })
        },
        // 设置不可选择多选
        selectable(row) {
            return this.time_decide(row.signup_end_time, '>') && row.state !== 5
        },
        handleSelectionChange(e) { this.chooseBatchActivity = e },
        // 打开批量优化的弹窗
        openBatch() {
            this.isEditShow = true;
            this.optimizeType = "batch";
        },
        // 清楚多选的门店
        toggleSelection() { this.$refs.activityTable.clearSelection(); },
        // 清理数据
        clearState() {
            this.chooseBatchActivity = [];
            this.chooseActivity = null;
            this.isEditShow = false;
            this.optimizeType = "";
            this.optimizeNumber = 1;
            this.toggleSelection();
        },
        // 单独优化
        getSingleActivity(item) {
            console.log(item);
            this.chooseActivity = item;
            this.optimizeType = "single"
            this.isEditShow = true;
        },
        // 提交优化表单
        submit() {
            let activityDatas = [];
            const { optimizeType, chooseBatchActivity, optimizeNumber, chooseActivity } = this;
            if (optimizeType === "batch") {
                chooseBatchActivity.map(item => activityDatas.push(item.activity_uuid));
            } else {
                activityDatas = [chooseActivity.activity_uuid];
            }
            let postdata = {
                api_name: "product.activity.setvirtualenrollment",
                token: this.Tool.get_l_cache('token'),
                activity_uuids: activityDatas,
                nums: optimizeNumber
            };
            this.Tool.post_data('oss', postdata, (json) => {
                this.editLoading = false;
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist();  // 刷新列表(重新搜索)
                            this.clearState();
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 清空优化数据
        clearOptimizeNumber() {
            const { chooseActivity } = this;
            let postdata = {
                api_name: "product.activity.clearvirtualenrollment",
                token: this.Tool.get_l_cache('token'),
                activity_uuids: [chooseActivity.activity_uuid],
            };
            this.Tool.post_data('oss', postdata, (json) => {
                this.editLoading = false;
                if (json.code === 0) {
                    this.$message({
                        message: '清空成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist();  // 刷新列表(重新搜索)
                            this.clearState();
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        exportList() {
            let postdata = {
                api_name: "product.activity.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            };
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data("oss", postdata, (res) => {
                if (res.code === 10005) {
                    this.Tool.errormes(res);
                } else {
                    location.href = res;
                }
                this.loading = false;
            });
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}
</style>
